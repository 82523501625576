import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import TitleCard from './TitleCard';
import GalleryContent from './GalleryContent';

import { CardContextProvider } from '../../../common/context/CardContext';
import { CONTENT_EVENTS } from '../../../../common/GtmApp';

export default class SeasonRow extends Component {
  static get contextTypes() {
    return {
      seasonId: PropTypes.number,
      getModelData: PropTypes.func,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
      columnsInRow: PropTypes.number,
    };
  }

  static get childContextTypes() {
    return {
      allSeasonCount: PropTypes.number,
      cardSize: PropTypes.string,
      columnsInRow: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.sendToGtm = this.sendToGtm.bind(this);

    this.state = {
      dispose: null,
    };
  }

  getChildContext() {
    const browserInfo = this.context.getModelData('browserInfo');
    return {
      cardSize: 'large',
      columnsInRow: browserInfo.isIOS || browserInfo.isAndroid ? 1.5 : this.context.columnsInRow,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  sendToGtm(seasonMeta) {
    if (!_.get(this.context, 'gtmApp')) return;
    const program = {
      refId: _.get(seasonMeta, 'refId'),
      name: _.get(seasonMeta, 'name'),
      rental: _.get(seasonMeta, 'rental'),
      subscription: _.get(seasonMeta, 'subscription'),
    };
    const attributes = _.get(seasonMeta, 'attributes');
    const genres = _.get(seasonMeta, 'genres');
    const middleGenres = _.get(seasonMeta, 'middleGenres');
    this.context.gtmApp.pushDataLayerOnContentPageClick(CONTENT_EVENTS.SELECT_PROGRAM, {
      program,
      attributes,
      genres,
      middleGenres,
    });
  }

  render() {
    let seasonRow = null;
    seasonRow = _.compact(
      // @ts-ignore TS2339
      _.map(this.props.seasons, (item, index) => {
        if (!item.id) return;
        return (
          <TitleCard
            // @ts-ignore TS2322
            titleId={item.id}
            // @ts-ignore TS2339
            model={this.props.model}
            listCard={false}
            key={`title-${item.id}-${index}`}
            enableCardClick={true}
            // @ts-ignore TS2339
            selected={item.id == this.props.selectedSeasonId}
          />
        );
      }),
    );

    return (
      <CardContextProvider value={this.getChildContext()}>
        <GalleryContent
          header={
            <div className="gallery-header season-row">
              <div className="title">
                <span className="gallery-title">シーズン</span>
              </div>
            </div>
          }
          // @ts-ignore TS2339
          model={this.props.model}
          // @ts-ignore TS2322
          titleSmallSize={true}
          hideAllWhenNoResults={true}
          showPopCardMylistButton={true}
          titleDisplaySetting={'media_only'}
          // @ts-ignore TS2339
          itemsInRow={this.props.seasons?.length}
        >
          {seasonRow}
        </GalleryContent>
      </CardContextProvider>
    );
  }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import Slider from '@logiclogic/react-slider';

import SpSlider from '../browse/SpSlider';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';
import BillboardCard from './BillboardCard';
import BillboardCardLoading from './BillboardCardLoading';
import BillboardThumbnailIndicator from './BillboardIndicator';
import PaletteImpression from '../browse/PaletteImpression';

const INITIAL_SLIDE = 0;
const AUTOPLAY_INTERVAL_MS = 5000;
class MountChecker extends React.Component {
  componentDidMount() {
    // @ts-ignore TS2339
    this.props.onMount();
  }
  render() {
    return this.props.children;
  }
}

type BillboardState = {
  muted: boolean;
  dispose: any;
  fetchDataError: any;
  activePage: number;
  activePageProgress: number;
  autoplay: boolean;
  generation?: any;
  fetchingMoreRows?: undefined;
  itemIndex?: any;
};

export default class Billboard extends React.Component<any, BillboardState> {
  static getPaths = function(models, options, props) {
    if (!props.paletteId) {
      return [];
    }

    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : props.isHome
            ? 9
            : 4,
      };
    };

    const rootPath = this.getRootPath(models, options, props);
    // @ts-ignore TS2554
    return BillboardCard.getPaths()
      .map(path => {
        return rootPath.concat(['objects', indexes(models, options, props)]).concat(path);
      })
      .concat([rootPath.concat(['objects', 'length'])])
      .concat([rootPath.concat([['id', 'title']])]);
  };

  static getRootPath = function(models, options, props) {
    if (props.paletteId) {
      return ['palette', props.paletteId];
    }
    return [];
  };

  static get propTypes() {
    return {
      firstDelay: PropTypes.number,
      duration: PropTypes.number,
      stop: PropTypes.bool,
      paletteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      listContext: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      enablePaginationIndicator: PropTypes.bool,
      enablePushOut: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      enablePaginationIndicator: true,
      enablePushOut: true,
    };
  }

  static get contextTypes() {
    return {
      popType: PropTypes.string,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
    };
  }

  private sliderRef: React.RefObject<Slider>;
  private rowContentRef: React.RefObject<HTMLDivElement>;
  private items: any;
  private maxItems: number;
  private totalItems: number;
  private _isMounted: boolean;
  private cancelAddActivePageProgressTimer: () => void;
  private isVisible: boolean;
  private __palette: any;
  private _isUpdated: boolean;
  private _player: any;

  constructor(props, context) {
    super(props, context);

    this.sliderRef = React.createRef();
    this.rowContentRef = React.createRef();

    this.maxItems = props.isHome ? 10 : 5;
    this.palette = props.model.getSync(`palette.${props.paletteId}`);
    this.totalItems = _.min([this.maxItems, _.get(this.palette, 'objects.length', 0)]);

    this.setAutoplay = this.setAutoplay.bind(this);
    this.clearAutoplay = this.clearAutoplay.bind(this);
    this.handleAutoplay = this.handleAutoplay.bind(this);
    this.handleMuted = this.handleMuted.bind(this);
    this.handleMount = this.handleMount.bind(this);
    this.onClickOther = this.onClickOther.bind(this);
    this.onClickDescriptionButton = this.onClickDescriptionButton.bind(this);
    this.onClickPlayButton = this.onClickPlayButton.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.onChangeVisible = this.onChangeVisible.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerTimeUpdate = this.onPlayerTimeUpdate.bind(this);
    this.onPlayerEnded = this.onPlayerEnded.bind(this);
    this.onSliderMove = this.onSliderMove.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.getActivePage = this.getActivePage.bind(this);
    this.onActivePageChange = this.onActivePageChange.bind(this);
    this.setTimerAddAutoplayActiveProgress = this.setTimerAddAutoplayActiveProgress.bind(this);
    this.handleWheel = this.handleWheel.bind(this);

    this.state = {
      muted: true,
      dispose: null,
      fetchDataError: null,
      activePage: INITIAL_SLIDE,
      activePageProgress: 0,
      autoplay: false,
    };
  }

  getChildContext() {
    return {
      listContext: this.props.listContext,
      isTallRow: this.props.listContext === 'originals',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.props);
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isTouchDevice) {
      this.rowContentRef.current?.addEventListener('wheel', this.handleWheel);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== prevState.activePage) {
      if (this.state.autoplay) {
        const item = this.items[this.state.activePage];
        if (item && !item.oap) {
          this.cancelAddActivePageProgressTimer = this.setTimerAddAutoplayActiveProgress();
        }
        // oap再生中の手動切り替え
      } else if (this.isVisible) {
        this.setState({ autoplay: true });
      }
    }
    if (this.state.autoplay !== prevState.autoplay) {
      if (this.state.autoplay) {
        this.setAutoplay();
      } else {
        this.clearAutoplay();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.dispose) this.state.dispose();
    if (this.cancelAddActivePageProgressTimer) {
      this.cancelAddActivePageProgressTimer();
      delete this.cancelAddActivePageProgressTimer;
    }
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isTouchDevice) {
      this.rowContentRef.current?.removeEventListener('wheel', this.handleWheel);
    }
  }

  set palette(palette) {
    this.__palette = palette;
    if (this.__palette) {
      if (this.__palette.objects) {
        this.items = _.compact(_.values(_.omit(this.__palette.objects, ['$__path', 'length'])));
        this.items = _.take(this.items, this.maxItems);
        // ビルボードは表示する件数が決まっているので、取得件数を正とする（total_countと取得件数に差異がある場合あり）
        // this.totalItems = Math.min(this.__palette.objects.length, this.maxItems);
        this.totalItems = this.items.length;
        if (this.props.noContents && !this.totalItems) this.props.noContents(this.props.paletteId);
      }
    }
  }

  get palette() {
    return this.__palette;
  }

  fetchData(props) {
    const pathProps = _.clone(props);
    // 固定
    pathProps.toNum = this.maxItems - 1;
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        this.palette = _.get(res, `json.palette.${this.props.paletteId}`);

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };

        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  setAutoplay() {
    setTimeout(() => {
      this.sliderRef.current.setAutoplay();
      const item = this.items[this.state.activePage];
      if (item && !item.oap) {
        this.cancelAddActivePageProgressTimer = this.setTimerAddAutoplayActiveProgress();
      }
    }, 0);
  }

  clearAutoplay() {
    // sliderコンポーネントの都合上
    if (this.sliderRef.current.autoplayID) {
      clearInterval(this.sliderRef.current.autoplayID);
      this.sliderRef.current.autoplayID = null;
    }
    if (this.cancelAddActivePageProgressTimer) {
      this.cancelAddActivePageProgressTimer();
      delete this.cancelAddActivePageProgressTimer;
    }
  }

  handleAutoplay(autoplay) {
    this.setState({ autoplay });
  }

  handleMuted(muted) {
    this.setState({ muted });
  }

  handleMount() {
    // if (this.state.autoplay) {
    //   this.setAutoplay();
    // } else {
    //   this.clearAutoplay();
    // }
  }

  onClickOther(item) {
    this.sendToGtm(CONTENT_EVENTS.CONTENT_CLICK, CLICK_AREA.CONTENT_CLICK.MUSTHEAD_OTHERS)(item);
  }

  onClickDescriptionButton(item) {
    this.sendToGtm(CONTENT_EVENTS.CONTENT_CLICK, CLICK_AREA.CONTENT_CLICK.MUSTHEAD_DETAIL)(item);
  }

  onClickThumbnail(item) {
    this.sendToGtm(CONTENT_EVENTS.CONTENT_CLICK, CLICK_AREA.CONTENT_CLICK.MUSTHEAD_THUMBNAIL)(item);
  }

  onClickPlayButton(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    this.sendToGtm(CONTENT_EVENTS.PLAY_CLICK, CLICK_AREA.PLAY_CLICK.MUSTHEAD)(item);
  }

  sendToGtm(contentEvents, clickArea) {
    return itemData => {
      if (!_.get(this.context, 'gtmApp')) return;
      const channel = _.get(itemData, 'linearChannelMeta');
      const hasRelationProgram = _.get(itemData, 'seriesMeta') && _.get(itemData, 'seasonMeta');
      const isGroupMeta = _.get(itemData, 'type') === 'group';
      const relationProgram = hasRelationProgram
        ? {
            refId: _.get(itemData, 'seriesMeta.refId'),
            name: _.get(itemData, 'seriesMeta.name'),
          }
        : null;
      const program = isGroupMeta
        ? {
            refId: _.get(itemData, 'refId'),
            name: _.get(itemData, 'name'),
          }
        : {
            refId: _.get(itemData, 'seasonMeta.refId') || _.get(itemData, 'seriesMeta.refId'),
            name: _.get(itemData, 'seasonMeta.name') || _.get(itemData, 'seriesMeta.name'),
          };
      let content = null;
      let ad = null;
      if (_.get(itemData, 'advertisingId')) {
        ad = {
          id: _.get(itemData, 'advertisingId'),
          name: _.get(itemData, 'name'),
        };
      } else {
        content = isGroupMeta
          ? {}
          : _.isEmpty(channel)
          ? {
              refId: _.get(itemData, 'refId'),
              name: _.get(itemData, 'name') || _.get(itemData, 'shortName'),
              rental: _.get(itemData, 'rental'),
              subscription: _.get(itemData, 'subscription'),
            }
          : {
              refId: _.get(itemData, 'uniqueId'),
              name: _.get(itemData, 'name'),
            };
      }

      const attributes = _.get(itemData, 'attributes');
      const genres = _.get(itemData, 'genres');
      const middleGenres = _.get(itemData, 'middleGenres');
      const schemaId = _.get(itemData, 'schemaId');
      this.context.gtmApp.pushDataLayerOnContentPageClick(
        contentEvents,
        { content, program, relationProgram, channel, attributes, genres, middleGenres, schemaId, ad },
        clickArea ? { clickArea } : null,
      );
    };
  }

  onChangeVisible(isVisible) {
    this.isVisible = isVisible;
    let autoplay = isVisible;
    if (this._player) {
      if (isVisible) {
        this._player.play();
        autoplay = false;
      } else {
        this._player.pause();
      }
      // 見える && playerがない場合
    } else if (isVisible) {
      autoplay = true;
    }
    this.handleAutoplay(autoplay);
  }

  onPlayerReady(player) {
    // console.log('onPlayerReady', player);
    this._player = player;
    if (this.isVisible) {
      this.handleAutoplay(false);
    }
  }

  onPlayerTimeUpdate(currentTime) {
    // console.log('onPlayerTimeUpdate', currentTime);
    if (this._player) {
      const duration = this._player.duration();
      this.setState({ activePageProgress: Math.floor((currentTime / duration) * 100) });
      // 見えてなければ止める
      if (!this.isVisible) {
        this._player.pause();
      }
    }
  }

  onPlayerEnded() {
    this.setState({ activePageProgress: 100 });
  }

  onSliderMove(nextStateChanges, __) {
    delete this._player;
    const nextPage = _.get(nextStateChanges, 'lowestVisibleItemIndex');
    this.onActivePageChange(nextPage);
  }

  goToPage(e) {
    if (this.sliderRef.current) {
      this.sliderRef.current.goToPage(e);
    }
  }

  getActivePage() {
    if (this.sliderRef.current) {
      return this.sliderRef.current.getActivePage();
    }
  }

  onActivePageChange(index) {
    if (this.cancelAddActivePageProgressTimer) {
      this.cancelAddActivePageProgressTimer();
      delete this.cancelAddActivePageProgressTimer;
    }
    this.setState({ activePage: index, activePageProgress: 0 });
  }

  setTimerAddAutoplayActiveProgress() {
    let intervalSum = 0;
    let activePageProgress = 0;
    const interval = 250;
    let cancel = false;
    let timer;
    const addActivePageProgress = () => {
      timer = setTimeout(() => {
        if (cancel) return;
        intervalSum += interval;
        activePageProgress = Math.floor((intervalSum / AUTOPLAY_INTERVAL_MS) * 100);
        this.setState({ activePageProgress });
        if (intervalSum < AUTOPLAY_INTERVAL_MS) {
          addActivePageProgress();
        }
      }, interval);
    };
    addActivePageProgress();
    const cancelAddActivePageProgress = () => {
      cancel = true;
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      if (this._isMounted) {
        this.setState({ activePageProgress: 0 });
      } else {
        // @ts-ignore TS2339
        this.state.activePageProgress = 0;
      }
    };
    return cancelAddActivePageProgress;
  }

  handleWheel(e: WheelEvent) {
    if (e.deltaX < -50 && this.sliderRef.current.hasPrev()) {
      e.preventDefault();
      this.sliderRef.current.advancePrev();
    } else if (e.deltaX > 50 && this.sliderRef.current.hasNext()) {
      e.preventDefault();
      this.sliderRef.current.advanceNext();
    }
  }

  render() {
    if (!this.items || this.items.length === 0) {
      if (!this.state.dispose) {
        return null;
      }
      return <BillboardCardLoading />;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    const showOap = !browserInfo.isTouchDevice;
    const cards = _.map(this.items, (item, idx) => {
      return (
        <BillboardCard
          // @ts-ignore TS2322
          autoplaySpeed={AUTOPLAY_INTERVAL_MS}
          key={`billboard-card-${item.id}`}
          onAutoplay={this.handleAutoplay}
          onMuted={this.handleMuted}
          muted={this.state.muted}
          popType={this.context.popType}
          model={this.props.model}
          rankNum={this.state.itemIndex}
          rowNum={this.props.rowNum}
          titleId={item.id}
          onClickOther={this.onClickOther}
          onClickDescriptionButton={this.onClickDescriptionButton}
          onClickPlayButton={this.onClickPlayButton}
          onClickThumbnail={this.onClickThumbnail}
          itemData={item}
          showOap={showOap}
          onPlayerReady={this.onPlayerReady}
          onPlayerTimeUpdate={this.onPlayerTimeUpdate}
          autoplay={this.isVisible}
        />
      );
    });

    const numOfItemRows = parseInt(this.palette.numOfRows, 10) || 1;
    const totalItems = Math.ceil(this.totalItems / numOfItemRows);
    return (
      <PaletteImpression
        paletteId={this.palette.id}
        paletteName={this.palette.title}
        orderNum={this.props.orderNum}
        useImpression={this.props.useImpression}
        onVisiblePalette={this.props.onVisiblePalette}
      >
        <div className={classnames('billboard-slider-row', { mb: browserInfo.isIOS || browserInfo.isAndroid })}>
          {browserInfo.isTouchDevice ? (
            // @ts-ignore TS2322
            <SpSlider totalItems={totalItems} scrollable={totalItems > 1}>
              {cards}
            </SpSlider>
          ) : (
            <VisibilitySensor onChange={this.onChangeVisible} partialVisibility={false} offset={{ top: 0, bottom: 0 }}>
              <div
                ref={this.rowContentRef}
                className={classnames('row-content', { 'has-indicator': this.items.length > 1 })}
              >
                {/*
               // @ts-ignore TS2769 */}
                <MountChecker onMount={this.handleMount}>
                  <Slider
                    ref={this.sliderRef}
                    itemsInRow={1}
                    totalItems={totalItems}
                    arrowTabbable={false}
                    initialSlide={INITIAL_SLIDE}
                    autoplay={this.state.autoplay}
                    // 多分ないけど怖いからignore
                    // @ts-ignore TS2339
                    swipe={false}
                    enableLooping={true}
                    enablePeekBeforeMoveOnce={true}
                    enablePeek={false}
                    enableArrows={true}
                    // これをtrueにしとかないと裏のコンポーネントを全てrenderしてくれないので仕方なくtrueにしてcssで消している
                    enablePaginationIndicator={true}
                    clickablePaginationIndicator={true}
                    enableSwipe={false}
                    autoAdjustWidth={false}
                    onSliderMove={this.onSliderMove}
                  >
                    {cards}
                  </Slider>
                  {this.items && this.items.length > 1 ? (
                    <BillboardThumbnailIndicator
                      // @ts-ignore TS2322
                      items={this.items}
                      goToPage={this.goToPage}
                      activePage={this.state.activePage}
                      activePageProgress={this.state.activePageProgress}
                    />
                  ) : null}
                </MountChecker>
              </div>
            </VisibilitySensor>
          )}
        </div>
      </PaletteImpression>
    );
  }
}
